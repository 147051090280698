<template>
  <div class="bg-blueGray-800 h-screen">
    <main>
      <div class="container relative mx-auto">
        <div class="title">
          <h1>Scan QR Code</h1>
          <p>Scan the participant's QR code</p>
        </div>
        <div class="flex justify-center">
          <stream-barcode-reader
            @decode="onDecode"
          ></stream-barcode-reader>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  components: {
    StreamBarcodeReader
  },
  methods: {
    onDecode(id) {
      this.$router.push({
        path: `/check-in/${id}`
      });
    },
  }
};
</script>

<style lang="sass" scoped>
.container
  padding: 50px 20px
  .title
    text-align: center
    color: white
    padding-bottom: 30px
    h1
      font-size: 40px
      font-weight: bold

.scanner-container
  max-width: 500px
  border: 5px solid white
  border-radius: 10px

:deep(video)
  width: 100%
  object-fit: cover
  aspect-ratio: 1/1
  border-radius: 10px
:deep(.overlay-element)
  height: 100%

:deep(.laser)
  display: none
</style>